<template>
  <div class="main_product__header">
    <div v-if="franchise.custom_message" class="custom_message_container">
      <div class="img_custom_message">
        <v-img
          :src="require('@/assets/img/Commercial.png')"
          alt="image 1"
        ></v-img>
      </div>
      <div class="text_custom_message ml-2">
        <marquee
          v-if="franchise.custom_message.length > 30"
          behavior="scroll"
          direction="left"
          >{{ franchise.custom_message }}</marquee
        >
        <div v-else>{{ franchise.custom_message }}</div>
      </div>
    </div>
    <div
      class="d-flex align-center flex-row mt-5"
      style="border: solid red 0px; padding: 20px"
    >
      <v-text-field
        v-model="searchQuery"
        @keyup="searchProduct"
        placeholder="Search"
        outlined
        single-line
        dense
        hide-details
        prepend-inner-icon="mdi-magnify"
        style="height: 38px; border-radius: 10px"
      ></v-text-field>
      <v-avatar size="30" color="" class="ml-2">
        <v-icon color="grey-300" large @click="goToUserDetail">
          mdi-account-circle
        </v-icon>
      </v-avatar>
    </div>

    <div class="products__headers__tabs__container" style="margin-top: -20px">
      <div class="products__headers__tabs__menu">
        <div ref="firsttag"></div>
        <div @click="scrollFirst" class="products__headers__tabs__left">
          <v-icon size="25" color="grey-300">mdi-menu-left</v-icon>
        </div>

        <div class="products__headers__tabs__item">
          <div
            @click="resetFilter"
            :class="
              selectedTag === 'All'
                ? 'products__headers__tabs__item__title products__headers__tabs__item_active'
                : 'products__headers__tabs__item__title'
            "
          >
            All
          </div>
        </div>
        <div
          v-for="(v, i) in tag_list"
          :key="i"
          class="products__headers__tabs__item"
        >
          <div
            @click="filterByTag(v)"
            :class="
              isActive(v)
                ? 'products__headers__tabs__item__title products__headers__tabs__item_active'
                : 'products__headers__tabs__item__title'
            "
          >
            {{ v.name }}
          </div>
        </div>
        <div ref="lasttag"></div>
        <div @click="scrollLast" class="products__headers__tabs__right">
          <v-icon size="25" color="grey-300">mdi-menu-right</v-icon>
        </div>
      </div>
    </div>

    <!-- <div style="box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2); margin-top: 10px; padding-top: 2px;">
      <v-tabs color="red" class="my-3" center-active hide-slider>
        <v-tab exact-active-class="v-tabsactive" @click="resetFilter" class="px-2">
          <p class="px-5 pt-2 pb-2 grey--text">
            All
          </p>
        </v-tab>
        <v-tab exact-active-class="v-tabsactive" style="border-bottom: 2px solid  #F85757;" v-for="(v, i) in tag_list" :key="i" @click="filterByTag(v)" class="px-2">
          <p class="px-5 pt-2 pb-2 v-tabsactive"  color="greener white--text"
            v-if="v.name == first">
            {{ v.name }}
          </p>
          <p class="px-5 pt-2 pb-2 grey--text" v-else>
            {{ v.name }}
          </p>
        </v-tab>
      </v-tabs>
    </div> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ProductHeader",
  props: {
    first: String,
    onSearchProduct: Function,
  },
  data() {
    return {
      searchQuery: "",
      selectedTag: "All",
    };
  },
  computed: {
    ...mapGetters({
      product: "getProduct",
      cart: "getCart",
      franchise: "getFranchise",
    }),

    tag_list() {
      var tags = {};
      this.product.forEach((prod) => {
        prod.tags.forEach(function (tag) {
          if (!tag.hidden_tag) {
            return (tags[tag.id] = tag);
          }
        });
      });
      return Object.values(tags).sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    scrollLast() {
      const lastItemRef = this.$refs.lasttag;

      if (lastItemRef) {
        lastItemRef.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    },
    scrollFirst() {
      const firstItemRef = this.$refs.firsttag;

      if (firstItemRef) {
        firstItemRef.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "center",
        });
      }
    },
    isActive(tag) {
      if (!tag) return true;
      return this.selectedTag && this.selectedTag.id == tag.id;
    },
    goToUserDetail() {
      const { outlet_id, table_id } = this.$route.params;
      const { session } = this.$route.params;
      if (outlet_id && table_id) {
        // STATIC QR
        this.$router.push({
          name: "StaticUser",
          params: {
            child: this.$route.params.child,
            outlet_id,
            table_id,
            page: "user",
          },
        });
      } else if (session) {
        // SESSION QR
        this.$router.push({
          name: "SessionUser",
          params: { session },
        });
      } else {
        // SHOP_LINK
        this.$router.push({
          name: "ShoplinkUser",
          params: { child: this.$route.params.child, page: "user" },
        });
      }
    },
    searchProduct() {
      this.$store.dispatch("handleSearchProductByName", this.searchQuery);
    },
    resetFilter() {
      this.selectedTag = "All";
      this.$store.dispatch("handleResetFilterProduct");
    },
    filterByTag(tag) {
      this.selectedTag = tag;
      this.$store.dispatch("handleFilterProductByTag", tag);
    },
  },
};
</script>
<style scoped lang="scss">
.custom_message_container {
  display: flex;
  position: sticky;
  top: 20px;
  width: 100%;
  height: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  background-color: #f85757;
}

.text_custom_message {
  font-size: 14px;
  color: #fff;
  white-space: nowrap;
  animation: moveLeft 10s linear infinite;
  margin-top: -3px;
}
.main_product__header {
  position: sticky;
  top: 0px;

  z-index: 200;
  background-color: white;
  // box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);
}
.products__headers__tabs__container {
  position: sticky;
  top: 0px;
  display: flex;
  width: 100%;
  margin-top: 10px;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.2);

  .products__headers__tabs__menu {
    margin-top: 20px;
    display: flex;
    overflow-x: scroll;
    width: 100%;
    align-items: center;

    &::-webkit-scrollbar {
      width: 0;
      /* Hide the scrollbar in WebKit (Safari and Chrome) */
    }

    .products__headers__tabs__left {
      position: sticky;
      left: 0px;
      background-color: white;
    }

    .products__headers__tabs__right {
      position: sticky;
      right: 0px;
      background-color: white;
    }

    .products__headers__tabs__item_active {
      color: #f85757 !important;

      border-bottom: 2px solid #f85757;
    }
    .products__headers__tabs__item {
      cursor: pointer;

      padding-left: 20px;
      padding-right: 20px;
      flex: 1;

      @media (min-width: 350px) and (max-width: 600px) {
        padding-left: 20px;
        padding-right: 20px;
      }
      .products__headers__tabs__item__title {
        font-size: 15px;
        font-weight: 600;
        color: gray;
        text-align: center;
        width: 130px !important;
        &:hover {
          color: #f85757;
        }
        @media (min-width: 350px) and (max-width: 600px) {
          width: 120px !important;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
